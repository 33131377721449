.svShare {
  padding: 0 12px;
  background: url('/img/svBG.png') bottom right/100%;
}

.imgStyle {
  flex-shrink: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border: none;
}

.imgStyles {
  position: absolute;
  border-radius: 3px;
  width: 100%;
  max-width: 52px;
  height: auto;
  object-fit: contain;
}

.imgStyleOne {
  top: 75px;
  left: 62px;
}

.imgStyleTwo {
  top: 35px;
  left: 63px;
}

.imgStyleEmail {
  position: absolute;
  border-radius: 7px;
  width: 100%;
  max-width: 94px;
  height: auto;
  object-fit: contain;
  border: 2px solid #5c9af5;
  top: 89px;
  left: 85px;
}
.blockImage {
  position: relative;
}

.svShareWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: inherit;
  background: rgba(69, 69, 69, 0.8);
  background: linear-gradient(80deg, rgba(69, 69, 69, 0.8), rgba(69, 69, 69, 1));
  border-radius: 16px;
  max-width: 400px;
  margin: 0 auto;
}

.infoBlock {
  background: #f0f0f0;
  border-radius: 16px;
  padding: 8px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.infoBlockImage {
  position: relative;
}

.playButton {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}

.infoBlockQuestion {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0 0;
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  height: 55px;
  padding: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.infoBlockAction {
  padding: 12px;
  position: absolute;
  width: 40px;
  height: 40px;
  right: -4px;
  top: -4px;
  background: #3b82f4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoBlockAction svg {
  width: 16px;
}

.infoBlockContent {
  padding: 16px;
  text-align: center;
}

.infoBlockContentTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: #000000;
}

button.btn.btnBook {
  background: #17b790;
}

button.btn.btnDefault {
  background: #4f96ff;
}

.btn {
  border-radius: 8px;
  width: 100%;
  height: 38px;
  padding: 0;
  border: none;
  outline: none;
  margin: 16px 0 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  transition: all 0.2s;
}

.btn i {
  line-height: 1;
}

.navBlock {
  width: 100%;
}

.shareBlock {
  width: 100%;
  text-align: center;
  margin-top: 16px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-wrap: wrap;
}

.shareBlockTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #848484;
  flex-basis: 100%;
}

.shareBlockIcons {
  flex: none;
  width: 40px;
  height: 40px;
}

button.btn.btnIcon {
  margin-top: 0;
  background: rgba(225, 225, 225, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

button.btn.btnIcon.active,
button.btn.btnIcon:active {
  background: #4f96ff;
}

section.svNav.share {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: #272727; */
  border-radius: 16px;
  margin: 0px auto;
  width: calc(100% - 24px);
  max-width: 400px;
}

.svNavTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.svNavSubTitle {
  margin-top: 8px;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.5;
}
.svNavSubTitle.center {
  text-align: center;
}
button.btn.btnSecondary {
  background: rgba(163, 180, 206, 0.5);
  border: 2px solid rgba(163, 180, 206, 0.8);
}
button.btn.btnOptional {
  background: #a3b4ce;
  margin-top: 8px;
}

.svNavContentBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 25px;
  min-height: 230px;
}

.containerWrapper {
  padding: 6px 10px;
  position: static;
  left: 0;
  top: 0;
  background: rgba(249, 245, 245, 0);
  border-radius: 8px;
}

label.container:only-child {
  width: 172px;
}

label.container:only-child .containerWrapper {
  padding: 0;
  transform: translateX(-13px);
}

.container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.subtitle {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-top: 20px;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  height: 16px;
  width: 16px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  transition: all 0.2s;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkmark svg {
  transform: scale(0);
  transition: all 0.2s;
}

.titleBlock {
  width: 100%;
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.5);
  margin: 20px 0 0;
  display: flex;
  align-items: center;
  padding: 5px 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 26px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .containerWrapper .checkmark,
.container input:checked ~ .containerWrapper .checkmark {
  border-color: #303030;
}

.container:hover input ~ .containerWrapper .titleBlock,
.container input:checked ~ .containerWrapper .titleBlock {
  color: #303030;
  background: #eeeded;
}

.container input:checked ~ .containerWrapper .checkmark svg {
  transform: scale(1);
}

.container input:checked ~ .containerWrapper {
  background: rgb(249 245 245);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
